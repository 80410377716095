import React from "react"
import { graphql, Link } from 'gatsby'
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  EmailShareButton,
  FacebookShareButton,
  LineShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TumblrShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  TelegramIcon,
  WhatsappIcon,
  RedditIcon,
  TumblrIcon,
  EmailIcon,
  LineIcon
} from "react-share";
import { Row, Col, Container, ListGroup } from "react-bootstrap"

const MDPage = ({ data }) => {
  
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const post = data.markdownRemark
  return (
    <Layout pageInfo={{ pageName: post.frontmatter.title }}>
      <SEO title={post.frontmatter.title} description={post.excerpt} />
      <Container className="mt-4">
        <div dangerouslySetInnerHTML={{ __html: post.html }} />
        <Row className="mt-3">
          Share:
        </Row>
        <Row>
          <Row>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <FacebookShareButton url={url}>
            <FacebookIcon size={32} borderRadius={5} />
          </FacebookShareButton>
          &nbsp;
          <TwitterShareButton url={url}>
            <TwitterIcon size={32} borderRadius={5} />
          </TwitterShareButton>
          &nbsp;
          <RedditShareButton url={url}>
            <RedditIcon size={32} borderRadius={5} bgStyle={{fill:'#FF5722'}}/>
          </RedditShareButton>
          &nbsp;
          <LinkedinShareButton url={url}>
            <LinkedinIcon size={32} borderRadius={5} />
          </LinkedinShareButton>
          &nbsp;
          <TelegramShareButton url={url}>
            <TelegramIcon size={32} borderRadius={5} />
          </TelegramShareButton>
          &nbsp;
          <LineShareButton url={url}>
            <LineIcon size={32} borderRadius={5} />
          </LineShareButton>
          &nbsp;
          <WhatsappShareButton url={url}>
            <WhatsappIcon size={32} borderRadius={5} />
          </WhatsappShareButton>
          &nbsp;
          <EmailShareButton url={url}>
            <EmailIcon size={32} borderRadius={5} />
          </EmailShareButton>
          &nbsp;
          <TumblrShareButton url={url}>
            <TumblrIcon size={32} borderRadius={5} />
          </TumblrShareButton>
          &nbsp;
          </Row>
        </Row>
        <br></br>
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
    }
  }
`
export default MDPage
